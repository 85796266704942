<template>

  <div style="text-align: center;position: absolute;top: 10%;left: 5%;width: 120%">
    <van-image
        width="330"
        :src="require('../assets/fr2.png')"
        radius="2"
    />
  </div>
  <div style="text-align: center;position: absolute;top: 20%;width: 100%">
    <span class="text">富日物流仓储管理</span>
  </div>
  <div style="margin-top: 45.33333vw">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
            v-model="tel"
            name="tel"
            label="手机号"
            type="tel"
            placeholder="手机号"
            :rules="[{ required: true, message: '请填写手机号'}, { pattern: /^((1[356789][0-9]{1})+\d{8})$/, message: '必须是手机号码格式'}]"
        />
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
        />
        <van-field
            v-model="vPassword"
            type="password"
            name="vPassword"
            label="再次输入密码"
            placeholder="再次输入密码"
            :rules="[{ required: true, message: '请再次输入密码' }]"
        />
        <van-cell-group>
          <van-field
              v-model="verificationCode"
              center
              clearable
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[{ required: true, message: '请填写验证码' }]"
          >
            <template #button>
              <span class="codeText" @click="sendCode" v-if="!sended">发送验证码</span>

              <van-count-down :time="time"  format="ss" @finish="resetSendCode" v-if="sended">
                <template #default="timeData">
                  <span class="codeText">已发送</span>
                  <span class="block">{{ timeData.seconds }}</span>
                </template>
              </van-count-down>

            </template>
          </van-field>
        </van-cell-group>

      </van-cell-group>
      <div style="margin: 4.26667vw">
        <van-button round block type="primary" native-type="submit">
          注册
        </van-button>
        <van-button round block type="default"
                    style="margin-top: 4.26667vw" @click="toLogin">
          已注册，点击登录
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {Toast} from "vant";
import _ from "lodash";

export default {
  name: "Register",
  data() {
    return {
      password: "",
      vPassword: "",
      tel: "",
      verificationCode: "",
      sended: false,
      time: 60 * 1000,
    };
  },
  methods: {
    onSubmit(values) {
      if (values.password !== values.vPassword) {
        Toast.fail('密码不一致，请重新输入。');
        return;
      }
         Toast.loading({
         message: "注册中...",
         forbidClick: true,
         duration: 0
       });
       this.$sapi
         .register(this.tel, this.password, this.verificationCode)
         .then(data => {
           Toast.success("注册成功");
           this.$router.replace("/");
         })
         .catch(err => {
            Toast.fail(err.message);
         })
    },
    sendCode() {
      var tel = this.tel;
      if (_.isEmpty(tel)) {
        Toast('请输入手机号')
        return;
      }
      var option = {
        portName: 'touch.system.TouchSecurity.sendSMSCode',
        data: {'type': 'REGISTER', tel:tel},
        needLoading: false,
        successCallback: (data) => {
          this.sended = true;
        }
      }
      this.$sapi.callPort(option);
    },
    resetSendCode() {
      this.time = 60 * 1000;
      this.sended = false;
    },
    toLogin() {
      var me = this;
      var username = me.username;
      var password = me.password;
      var tel = me.tel;
      this.$router.replace({
        name: 'login', params: {
          username: username,
          password: password, tel: tel
        }
      })
    }
  },
  created() {
    var me = this;
    var tel = me.$route.params.tel;
    me.tel = tel;
  }
}
</script>

<style scoped>
.codeText {
  display: inline-block;
  color: #fff;
  font-size: 12px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #1989fa;
}
</style>